<template>
  <div>
    <router-view
      @checkDuplicate="checkDuplicate"
      :event="event"
      :eventId="$route.params.id"
      :getBuy="getBuy"
    />
    <event-snackbar />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import socket from '@socket/client'
import EventSnackbar from '@/components/organisms/EventSnackbar'
import mixinPurchase from '@/mixins/purchase'

export default{
  name: 'Event',
  data(){
    return{
      ws: null,
      event: {},
      deviceId: 'kokoni-device-id-ga-hairimasu',
      isMobile: false,
    }
  },

  computed:{
    ...mapGetters( ['getItem', 'getHash', 'getBuy']),
  },
  components:{
    EventSnackbar
  },
  mixins: [mixinPurchase],

  async created(){
    // イベント情報取得
    [this.event] = await this.wc.get('v_event', `w/id,${this.$route.params.id}`).catch((err) => {
      return err
    })
    // ローカルストレージ復元
    this.restore()
    this.set({ eventId: this.eventId })
    // 非公開の場合、リダイレクト
    if(this.event.is_published == 0) this.$router.push('/private')
    // 決済後にリダイレクトされるURL（codeId付き）の処理
    await this.confirmPurchased()
    // 二重端末チェック
    if(this.getBuy) await this.checkDuplicate()
    // WebSocket接続
    await this.connectWebSocket()
  },
  provide(){
    return {
      connectWebSocket: this.connectWebSocket
    }
  },
  methods:{
    ...mapActions( ['set', 'restore']),

    async confirmPurchased(){
      // codeIdの存在チェック
      const url = location.href
      const regex = new RegExp("[?&]codeId(=([^&#]*)|&|#|$)"),
            results = regex.exec(url)
      if(!results) return
      
      // 支払成功判定
      const status = Array.isArray(this.$route.query.status) ? 
        this.$route.query.status : [this.$route.query.status]
      if(!status.includes('captured')) return

      // 購入データとcodeIdの照合
      const codeId = results[2]
      const [purchaseData] = await this.wc.get('v_purchase',`w/object_code,${codeId}`).catch((err) => {
        console.log(err)
      })
      if(!purchaseData || purchaseData.hash != this.getHash 
        || !purchaseData.is_purchased) return
      
      this.$_setPurchase(String(this.$route.params.id)) // mixin
    },
    // WebScoket接続（二重接続確認用）
    async connectWebSocket(){
      if(this.event.is_end) return
      if(this.ws) this.ws.close()

      const username = this.getHash ? this.getHash : 'notpay'
      const socketUrl = `${this.config.ws}event_${this.$route.params.id}/${username}/type:main`
      this.ws = await socket.client(socketUrl).catch((err) => {
        console.log(err)
      })
      this.ws.on('message', (message, from) => {
        console.log('ws message: ', message)
        this.onMessage(message, from)
      })
    },
    // webSocket onMessage
    async onMessage(message){
      console.log('Event.vue onMessage', message)
      // イベントステータス
      if("status" in message){
        [this.event] = await this.wc.get('v_event', `w/id,${this.$route.params.id}`).catch((err) => {
          console.log(err)
        })
      }
      // 他端末からの同時接続
      if("duplicate" in message && this.event.is_one_device){
        if(!this.getItem) return
        // if(this.event.show_video) this.$refs.video.pause()
        // TODO:コンテンツの配信を停止する
        window.alert("他の端末でログインがありました")
        this.ws.close()
        
        this.$_clearPurchase(String(this.$route.params.id))
        this.$router.go({name:"event", params:{id: this.$route.params.id}})
      }
    },
    // 二重端末チェック
    async checkDuplicate(){
      const isConnected = this.ws ? 1 : 0
      if(isConnected) this.ws.close()
      await this.wc.post('checkDuplicate', [{'event_id':this.$route.params.id, 'hash':this.getHash}]).catch((err) => {
        console.log(err)
      })
      if(isConnected) await this.connectWebSocket()
    }
  }
}

</script>
