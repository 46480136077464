<template>
  <v-snackbar v-model="isShow">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn 
        v-bind="attrs" 
        @click="closeSnackbar"
      >
        CLOSE
      </v-btn>
       
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions } from 'vuex'

export default{
  name: 'eventSnackbar',

  data(){
    return {
      isShow: false,
      message : ''
    }
  },
  methods:{
    ...mapActions("snackbar", ["closeSnackbar"])
  },
  created(){
    this.$store.subscribe((mutation, state) => {
      if( mutation.type === "snackbar/setSnackbar"){
        this.message = state.snackbar.message
        this.isShow = true
      } else if( mutation.type === "snackbar/unsetSnackbar"){
        this.message = ""
        this.isShow = false
      }
    })
  }
}
</script>
<style></style>
